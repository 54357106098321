export const CATEGORIES = {
  persona: {
    // imgCount: 34,
    // description: `In the delicate dance of revelation, we cast off the cloaks of
    //                 artifice, allowing our essence to radiate freely. The canvas of
    //                 self, once obscured, now breathes with vibrant strokes of genuine
    //                 expression. Through the cracks, the light of our truest selves
    //                 pierces, illuminating the path to connection and understanding.`,
    description: `Many—shaped by the world and our own desires, ever-changing with time. In every face, a story lingers, and in every gaze, a question: Who am I today, and who will I be tomorrow? Maybe we are just fragments of self, fleeting moments where our true faces flicker before they slip back behind the masks we wear. To see ourselves fully is to embrace the unknown within.`,
    imgDetail: [
      "p1",
      "p2",
      "p3",
      "p4",
      "p5",
      "p6",
      "p7",
      "p8",
      "p9",
      "p10",
      "p11",
      "p12",
      "p13",
      "p14",
      "p15",
      "p16",
      "p17",
      "p18",
      "p19",
      "p20",
      "p21",
      "p22",
      "p23",
      "p24",
      "p25",
      "p26",
      "p27",
      "p28",
      "p29",
      "p30",
      "p31",
      "p32",
      "p33",
      "p34",
    ],
  },
  voyage: {
    description: `We stand still, yet we are in motion. Each step is a quiet search for something—sometimes a place, sometimes a feeling, sometimes a version of ourselves yet to be found. People move toward the horizon, not knowing what lies ahead but trusting in the road beneath them. The path itself becomes the destination, as each footfall whispers of transformation and discovery. We are never truly lost, only wandering through the landscapes of our own making.`,
    imgDetail: [
      "v1",
      "v2",
      "v3",
      "v4",
      "v5",
      "v6",
      "v7",
      "v8",
      "v9",
      "v10",
      "v11",
      "v12",
      "v13",
      "v14",
      "v15",
      "v16",
      "v17",
      "v18",
      "v19",
      "v20",
      "v21",
      "v22",
      "v23",
      "v24",
      "v25",
      "v26",
      "v27",
      "v28",
      "v29",
      "v30",
      "v31",
      "v32",
      "v33",
      "v34",
      "v35",
      "v36",
      "v37",
      "v38",
      "v39",
      "v40",
      "v41",
      "v42",
      "v43",
      "v44",
    ],
  },
  hearth: {
    description: `Home is not just a place—it is a feeling, a memory, a heartbeat that stays with us no matter where we go. In the streets and rooms we once lived in, carved out pieces of ourselves, leaving behind traces of our existence. Maybe that's why we return to it, again and again. The hearth is where we feel most human, where the noise of the world fades and the quiet of our belonging fills the air.
`,
    imgDetail: [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "h7",
      "h8",
      "h9",
      "h10",
      "h11",
      "h12",
      "h13",
      "h14",
      "h15",
      "h16",
      "h17",
      "h18",
      "h19",
      "h20",
      "h21",
      "h22",
      "h23",
      "h24",
      "h25",
      "h26",
      "h27",
      "h28",
      "h29",
      "h30",
      "h31",
      "h32",
      "h33",
      "h34",
      "h35",
      "h36",
      "h37",
      "h38",
      "h39",
      "h40",
      "h41",
      "h42",
      "h43",
      "h44",
      "h45",
      "h46",
      "h47",
    ],
  },
  mirror: {
    description: `A truest mirror is always the nature, reflecting not the world around us, but the depths within. Yet it offers no answers, only the quiet realization that we are both part of the vastness and searching for our place within it. We long for a meaning in every corner of life, yet in this stillness of the mountains, do you finally see yourself?`,
    imgDetail: [
      "m1",
      "m2",
      "m3",
      "m4",
      "m5",
      "m6",
      "m7",
      "m8",
      "m9",
      "m10",
      "m11",
      "m12",
      "m13",
      "m14",
      "m15",
      "m16",
      "m17",
      "m18",
      "m19",
      "m20",
      "m21",
      "m22",
      "m23",
      "m24",
      "m25",
      "m26",
      "m27",
    ],
  },
};
