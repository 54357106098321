import { Link } from "react-router-dom";

function Landing() {
  return (
    <>
      <div className="landing">
        <div className="body-container">
          {/* <div className="cursor"></div> */}
          <div className="heading">Ashmit Galav</div>
          <div className="menu-links">
            <div className="category">
              <Link to="persona">Persona</Link>
            </div>
            <div className="category">
              <Link to="voyage">Voyage</Link>
            </div>
            <div className="category">
              <Link to="hearth">Hearth</Link>
            </div>
            <div className="category">
              <Link to="mirror">Mirror</Link>
            </div>
            <div className="category2">
              <Link to="about">About</Link>
            </div>
            <div className="category2">
              <Link to="https://foundation.app/@ashmitgalav">Marketplace</Link>
            </div>
          </div>
          {/* <div className="menu-links">
            
          </div> */}
        </div>
        {/* <img src={require(`../static/images/main/l1.jpg`)} alt="" /> */}
      </div>
    </>
  );
}

export default Landing;
