import "./styles/app.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Portfolio from "./components/portfolio";
import Landing from "./components/landing";
import About from "./components/about";
// import { useEffect, useRef } from "react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/:genreName",
    element: <Portfolio />,
  },
  {
    path: "/about",
    element: <About />,
  },
]);

function App() {
  // const cursor = useRef(null);

  // const editCursor = (e) => {
  //   const { clientX: x, clientY: y } = e;
  //   cursor.current.style.left = x + "px";
  //   cursor.current.style.top = y + "px";
  // };

  // useEffect(() => {
  //   window.addEventListener("mousemove", editCursor);

  //   // cleanup this component
  //   return () => {
  //     window.removeEventListener("mousemove", editCursor);
  //   };
  // }, []);

  return (
    <div className="app">
      <RouterProvider router={router} />
      {/* <div className="cursor" ref={cursor}></div> */}
    </div>
  );
}

export default App;
