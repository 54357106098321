import { Link } from "react-router-dom";

function About() {
  return (
    <div className="about">
      <img src={require(`../static/images/main/about.jpg`)} alt="" />
      {/* <div className="text"> */}
      <div className="t1">
        Traversing on the path of isolation to liberation of one’s self and in
        finding tranquility when surrounded by chaos.
      </div>
      <div className="t2">
        The mountains of our past gave us a purpose to delve into nature, to
        traverse our emotions and get the wind of our dreams, what they looked
        and felt like. These photographs are but a glimpse into our narratives.
        Giving visuals through cinema, I want us to breathe again. <br></br>
        <br></br>
        Ultimately, this is a tale of embracing what is.<br></br>
        <br></br>
        <div className="links">
          <Link to="https://linktr.ee/ashmitgalav" target="_blank">
            Linktree
          </Link>
          <Link to="mailto:ashmitgalav@gmail.com" target="_blank">
            ashmitgalav@gmail.com
          </Link>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default About;
