import { useParams } from "react-router-dom";
import { CATEGORIES } from "../static/database";

function Portfolio() {
  let { genreName } = useParams();
  let genre = CATEGORIES[genreName];

  console.log(genreName, genre);

  if (!genre) {
    return <> 404: Not Found </>;
  }

  let images = [];
  for (let img of genre.imgDetail) {
    images.push(
      <img src={require(`../static/images/${genreName}/${img}.jpg`)} alt="" />
    );
  }
  // for (let img = 1; img <= genre.imgCount; img++) {
  //   images.push(
  //     <img
  //       src={require(`../static/images/${genreName}/${genreName[0] + img}.jpg`)}
  //       alt=""
  //     />
  //   );
  // }
  return (
    <>
      <div className="portfolio">
        <div className="heading">
          <div className="title"> {genreName} </div>
          <div className="description"> {genre.description} </div>
        </div>
        <div className="img-container"> {images}           
          <div className="copyright"> &#169; Ashmit Galav 2024 </div>

          </div>

      </div>
    </>
  );
}

export default Portfolio;
